import { useState, useEffect  } from 'react';
import API from '../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Alert,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { FormText } from '../Objects/Form';
import { ModalAlert } from '../Objects/Modal';


const Default = () => {
	
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [refresh, setRefresh] = useState([true]);
	const [date_now, setDateNow] = useState(0);
	
	useEffect(() => {
	
		const getData = async () => {
			
			const datetime = covertTime(new Date());
			
			const newData = {};
			
			newData.EXE = datetime + (60 * 60 * 2);
			newData.TTL = newData.EXE + (60 * 60 * 10);
			newData.EC2 = 100;
			newData.RDS = 16;
			
			//保存
			setData(newData);
			setDateNow(datetime);
			
			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const covertTime = (date_value) => {
		return(date_value ? Math.floor((new Date(date_value)).getTime() / 1000) : null);
	};
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
		
		try {
			if((date_now + (60 * 60 * 1)) > data.EXE){
				throw new Error('実行日時は現在日時より1時間以上先の未来を指定してください。');
			}

			if(!data.TTL){
				throw new Error('終了日時を指定してください。');
			}

			if((data.EXE + (60 * 60 * 1)) > data.TTL){
				throw new Error('終了日時は実行日より1時間以上先の未来を指定してください。');
			}
			
			if(!data.EC2){
				throw new Error('WEBサーバー台数を指定してください。');
			}
			
			if(!data.RDS){
				throw new Error('DBキャパシティを指定してください。');
			}
			
			//更新
			const ref = await API.Data.PutReserve({
				EXE: data.EXE,
				TTL: data.TTL,
				EC2: data.EC2,
				RDS: data.RDS,
			});
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。' + ref.error);
			}
			
			throw new Error('予約しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		console.log(name);
		console.log(type);
		console.log(value);
		
		const newData = data;
		
		//対象が日付かどうか
		if(type === 'datetime-local'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = (alert) => {
		//console.log(alert);
		if(alert[0] === '予約しました。'){
			window.location.href='/reserve-list/';
		}
		else{
			handleReload();
		}
	};
	
	const dateShaping = (datetime) => {
		if(datetime){
			const dateObj      = new Date(Number(datetime * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			return(year + '-' + month + '-' + day + 'T' + hour + ':' + minute);
		}
		else{
			return('');
		}
	
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={()=>handleClose(alert)} />

				<Form name="form">
				
					<Row>
						<Col sm={6}>
							<p><big>予約登録</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<Alert variant="info">
						登録された実行日時にスケールアウトを実行します。実行日時に指定された時間より最大10分以内の実行となります。<br/>
						他のスケールアウト実行と予約が重なった場合は、先に実行されるスケールアウトが優先され予約は取り消されます。
					</Alert>
					<hr/>
					<FormText label={'実行日時'} name={'EXE'} value={dateShaping(data.EXE ? data.EXE : '')} type={'datetime-local'} onChange={handleOnChange} required={true}  />
					<FormText label={'終了日時'} name={'TTL'} value={dateShaping(data.TTL ? data.TTL : '')} type={'datetime-local'} onChange={handleOnChange} required={true} />
					<FormText label={'WEBサーバー台数'} name={'EC2'} value={data.EC2 ? data.EC2 : 0} type={'number'} min={3} max={400} onChange={handleOnChange} required={true} />
					{/*<FormSelect label={'DBキャパシティ'} name={'RDS'} value={data.RDS ? data.RDS : 0} data={ [1, 2, 4, 8, 16, 32, 64, 128, 256] } onChange={handleOnChange} required={true} />*/}
					<Alert variant="warning">
						データベースはAuroraServerlessV1からV2にバージョンアップしたため、DBキャパシティは自動で調整されます。
					</Alert>
					<hr/>
					<Row style={{ marginTop: 30 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block disabled={data.ID ? true : false} onClick={handleSubmit}>予約登録</Button>
						</Col>
					</Row>
				</Form>




			</Container>
		);
	}
};

export default withRouter(Default);
