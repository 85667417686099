import { useState, useEffect  } from 'react';
import API from '../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Alert,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { FormText } from '../Objects/Form';
import { ModalAlert } from '../Objects/Modal';


const Default = () => {
	
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [refresh, setRefresh] = useState([true]);
	const [date_now, setDateNow] = useState(0);
	
	useEffect(() => {
	
		const getData = async () => {
			
			const datetime = covertTime(new Date());
			
			
			//データ取得
			const items = (await API.Data.Get()).items;

			//ソート
			items.sort((a, b) => {
				if (a.ADD > b.ADD) {
					return 1;
				} else {
					return -1;
				}
			});

			const newData = {};
			
			if(items.length){
				newData.ID = items[0].ID;
				newData.ADD = items[0].ADD;
				newData.TTL = items[0].TTL;
				newData.EC2 = items[0].EC2;
				newData.RDS = items[0].RDS;
			}
			else{
				newData.TTL = datetime + (60 * 60 * 8);
				newData.EC2 = 100;
				newData.RDS = 16;
			}
			
			//保存
			setData(newData);
			setDateNow(datetime);
			
			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const covertTime = (date_value) => {
		return(date_value ? Math.floor((new Date(date_value)).getTime() / 1000) : null);
	};
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
		
		try {
			if(!data.TTL){
				throw new Error('終了日時を指定してください。');
			}
			
			if( (date_now + (60 * 60 * 1)) > data.TTL){
				throw new Error('終了日時は現時刻より1時間以上先の未来を指定してください。');
			}
			
			if(!data.EC2){
				throw new Error('WEBサーバー台数を指定してください。');
			}
			
			if(!data.RDS){
				throw new Error('DBキャパシティを指定してください。');
			}
			
			//更新
			const ref = await API.Data.Put({
				TTL: data.TTL,
				EC2: data.EC2,
				RDS: data.RDS,
			});
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。' + ref.error);
			}
			
			throw new Error('実行しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		
	};
	
	const handleDelete = async (event) => {
		
		try {

			//更新
			const ref = await API.Data.Delete(data.ID);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。');
			}
			
			throw new Error('中止しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		console.log(name);
		console.log(type);
		console.log(value);
		
		const newData = data;
		
		//対象が日付かどうか
		if(type === 'datetime-local'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		handleReload();
	};
	
	const dateShaping = (datetime) => {
		if(datetime){
			const dateObj      = new Date(Number(datetime * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			return(year + '-' + month + '-' + day + 'T' + hour + ':' + minute);
		}
		else{
			return('');
		}
	
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form name="form">
				
					<Row>
						<Col sm={6}>
							<p><big>スケール実行</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<Alert variant="info">
						実行されたスケールアウトは指定された終了日時に自動的にスケールインします。<br/>
						スケール停止を行った場合は、即時にスケールインを実行します。<br/>
						スケール状況については<a href="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=TOPJRecords&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTk4NDQ5NzU2NzE1NCIsIlUiOiJ1cy1lYXN0LTFfUXdrZUF1TmZrIiwiQyI6IjVvbHJjZjMyNHQyajd1dmk0M2p0cHJqbzBnIiwiSSI6InVzLWVhc3QtMTo2YTlmOWRlYy1hOThlLTRmMWYtYjFkYS02YmYxODgyNmI2ZGMiLCJPIjoiYXJuOmF3czppYW06Ojk4NDQ5NzU2NzE1NDpyb2xlL3NlcnZpY2Utcm9sZS9DbG91ZFdhdGNoRGFzaGJvYXJkLVJlYWRPbmx5QWNjZXNzLVRPUEpSZWNvcmRzLUpSNVJIV1A0IiwiTSI6IlVzclB3U2luZ2xlIn0=" target="_blank" rel="noreferrer">こちら</a>のCloudWatchダッシュボードからご確認ください。。<br/>
						スケール超過分の課金などのついては<a href="/history/" rel="noreferrer">利用状況</a>のページからご確認ください。
					</Alert>
					<hr/>
					<FormText label={'ステータス'} name={'ID'} value={data.ID ? 'スケール実行中' : '未実行'} readOnly={true} />
					<FormText label={'実行ID'} name={'ID'} value={data.ID ? data.ID : ''} readOnly={true} />
					<FormText label={'実行日時'} name={'ADD'} value={dateShaping(data.ADD ? data.ADD : '')} type={'datetime-local'} readOnly={true} />
					<FormText label={'終了日時'} name={'TTL'} value={dateShaping(data.TTL ? data.TTL : '')} type={'datetime-local'} onChange={handleOnChange} required={true} readOnly={data.ID ? true : false} />
					<FormText label={'WEBサーバー台数'} name={'EC2'} value={data.EC2 ? data.EC2 : 0} type={'number'} min={3} max={400} onChange={handleOnChange} required={true} readOnly={data.ID ? true : false} />
					{/*<FormSelect label={'DBキャパシティ'} name={'RDS'} value={data.RDS ? data.RDS : 0} data={ [1, 2, 4, 8, 16, 32, 64, 128, 256] } onChange={handleOnChange} disabled={true} />*/}
					<Alert variant="warning">
						データベースはAuroraServerlessV1からV2にバージョンアップしたため、DBキャパシティは自動で調整されます。
					</Alert>
					<hr/>
					<Row style={{ marginTop: 30 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block disabled={data.ID ? true : false} onClick={handleSubmit}>スケール実行</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 30 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="button" block disabled={data.ID ? false : true} onClick={handleDelete}>スケール停止</Button>
						</Col>
					</Row>
				</Form>




			</Container>
		);
	}
};

export default withRouter(Default);
