import {
	Navbar,
	Nav,
	NavDropdown,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';

const Default = () => {

	const history = useHistory();

	const handleClick = (uri) => {
		if(uri){
			history.push(uri);
		}
	};
	return (
		<div>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="no_print">
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav onSelect={handleClick} className="mr-auto">
						<Nav.Link eventKey={"/"}>メイン</Nav.Link>
						{/*<Nav.Link eventKey={"/history"}>利用状況</Nav.Link>*/}
						<Nav.Link eventKey={"/reserve-list"}>予約機能</Nav.Link>
					</Nav>
					<Nav onSelect={handleClick}>
						<NavDropdown title="ログイン情報" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/account/"}>アカウント情報</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/sign-out/"}>ログオフ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/"} style={{color:'white'}}>ver.2.4</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default withRouter(Default);

